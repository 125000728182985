/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
export const setting = {
  language: {
    list: {
      bm: 'Bahasa Melayu',
      en: 'English',
    },
    title: {
      bm: 'Bahasa',
      en: 'Language',
    }
  },
  login: {
    main_text: {
      bm: 'Idea Bina Negara ialah satu platform digital bagi membolehkan rakyat Malaysia terutama golongan belia mengemukakan idea dan pandangan kepada kerajaan bagi penyediaan Bajet 2023 serta aspirasi anak muda Malaysia ke arah pembangunan negara secara umum.',
      en: 'Idea Bina Negara is a digital platform to enable Malaysians, especially the youth, to submit ideas and views to the government for the preparation of the 2023 Budget as well as the aspirations of young Malaysians towards national development in general.',
    },
    ideas: {
      bm: 'IDEA',
      en: 'IDEAS',
    },
    respondent: {
      bm: 'RESPONDEN',
      en: 'RESPONDENT',
    },
    sign_in_text: {
      bm: 'Sila log masuk untuk<br>menyumbang idea',
      en: 'Please log in to<br>contribute ideas',
    },
    sign_in_with_text: {
      bm: 'Log masuk dengan',
      en: 'Log in with',
    },
    sign_in_as_text: {
      bm: 'Log masuk sebagai',
      en: 'Log in as',
    },
    sign_in_as_admin_text: {
      bm: 'Log masuk sebagai<br>PENTADBIR',
      en: 'Log in as<br>ADMIN',
    },
    unauthorized_text: {
      bm: 'Akses tidak dibenarkan!',
      en: 'Unauthorized Access!',
    },
    provider: {
      google: {
        icon: 'logo-google',
        order: 1
      },
      apple: {
        icon: 'logo-apple',
        order: 2
      },
      facebook: {
        icon: 'logo-facebook',
        order: 3
      },
      twitter: {
        icon: 'logo-twitter',
        order: 4
      },
      microsoft: {
        icon: 'logo-microsoft',
        order: 5
      },
      yahoo: {
        icon: 'logo-yahoo',
        order: 6
      },
    }
  },
  idea_type: {
    desc: {
      bm: 'Sila pilih kategori bagi idea yang ingin anda sumbangkan',
      en: 'Please select a category for the idea you wish to contribute',
    },
    desc_admin: {
      bm: 'Sila pilih kategori',
      en: 'Please select a category',
    },
    list: {
      bajet: {
        bm: 'Bajet 2023',
        en: 'Budget 2023',
        desc: {
          bm: 'Pilih kategori ini jika idea yang anda ingin kemukakan melibatkan peruntukan Kerajaan dan perlu dilaksanakan dengan segera pada tahun hadapan. Kerajaan akan mempertimbang dan menentukan keupayaan dan kebolehlaksanaan idea tersebut berdasarkan keutamaan dan prestasi semasa kewangan negara.',
          en: 'Select this category if the idea you want to contribute involves Government fund allocation and needs to be implemented immediately in the next year. The government will consider and determine the ability and feasibility of the idea based on priorities and the country\'s current financial performance.',
        },
        order: 1,
      },
      aspirasi: {
        bm: 'Aspirasi Anak Muda Malaysia',
        en: 'Aspirasi Anak Muda Malaysia',
        desc: {
          bm: 'Pilih kategori ini jika idea anda menyentuh pembangunan dan kemajuan negara secara menyeluruh dan tidak spesifik kepada Bajet 2023 yang bakal dibentangkan. Idea anda akan dikumpul, diperhalusi dan diambilkira untuk perancangan polisi dan tindakan Kerajaan pada masa akan datang.',
          en: 'Choose this category if your idea touches on the development and progress of the country as a whole and is not specific to the 2023 Budget that will be presented soon. Your ideas will be collected, refined and taken into account for the planning of policies and actions of the Government in the future.',
        },
        order: 2,
      }
    }
  },
  segment: {
    desc: {
      bm: 'Sila pilih segmen bagi idea yang ingin disumbangkan.',
      en: 'Please select the segment for the idea you want to contribute.',
    },
    desc_admin: {
      bm: 'Sila pilih segmen',
      en: 'Please select the segment',
    },
    list: {
      politic: {
        bm: 'Politik',
        en: 'Politic',
        order: 1,
        sub_segment: {
          stability: {
            bm: 'Kestabilan Politik',
            en: 'Political Stability',
            order: 1
          },
          performance: {
            bm: 'Prestasi Kerajaan',
            en: 'Goverment Performance',
            order: 2
          }
        }
      },
      economy: {
        bm: 'Ekonomi',
        en: 'Economy',
        order: 2,
        sub_segment: {
          occupation: {
            bm: 'Pekerjaan',
            en: 'Occupation',
            order: 1
          },
          business: {
            bm: 'Perniagaan',
            en: 'Business',
            order: 2,
          },
          salarywages: {
            bm: 'Gaji & Upah',
            en: 'Salary & Wages',
            order: 3,
          },
          foodsecurity: {
            bm: 'Keselamatan Makanan',
            en: 'Food Security',
            order: 4,
          },
          priceofgoods: {
            bm: 'Harga Barangan',
            en: 'Price of Goods',
            order: 5,
          },
          costliving: {
            bm: 'Kos Sara Hidup',
            en: 'Cost of Living',
            order: 6,
          },
          skilledlabours: {
            bm: 'Tenaga Kerja Mahir',
            en: 'Skilled Labours',
            order: 7,
          },
          inflation: {
            bm: 'Inflasi',
            en: 'Inflation',
            order: 8,
          },
          interestrate: {
            bm: 'Kadar Faedah Bank',
            en: 'Interest Rate',
            order: 9,
          },
          unemployment: {
            bm: 'Pengangguran',
            en: 'Unemployment',
            order: 10,
          }
        }
      },
      social: {
        bm: 'Sosial',
        en: 'Social',
        order: 3,
        sub_segment: {
          education: {
            bm: 'Pendidikan',
            en: 'Education',
            order: 1,
          },
          sportsrecreation: {
            bm: 'Sukan & Rekreasi',
            en: 'Sports & Recreation',
            order: 2,
          },
          lifestyle: {
            bm: 'Gaya Hidup',
            en: 'Lifestyle',
            order: 3,
          },
          healthcare: {
            bm: 'Kesihatan',
            en: 'Healthcare',
            order: 4,
          },
          values: {
            bm: 'Nilai Murni',
            en: 'Values',
            order: 5,
          },
          familyinstitution: {
            bm: 'Institusi Kekeluargaan',
            en: 'Family Institution',
            order: 6,
          },
          genderequality: {
            bm: 'Kesaksamaan Jantina',
            en: 'Gender Equality',
            order: 7,
          },
          culturalheritageunity: {
            bm: 'Budaya, Warisan & Perpaduan',
            en: 'Cultural, Heritage & Unity',
            order: 8,
          },
        }
      },
      technology: {
        bm: 'Teknologi',
        en: 'Technology',
        order: 4,
        sub_segment: {
          skills: {
            bm: 'Kemahiran',
            en: 'Skills',
            order: 1,
          },
          internetcoverage: {
            bm: 'Liputan Internet',
            en: 'Internet Coverage',
            order: 2,
          },
          cybersecurity: {
            bm: 'Keselamatan Siber',
            en: 'Cyber Security',
            order: 3,
          },
        }
      },
      environment: {
        bm: 'Persekitaran',
        en: 'Environment',
        order: 5,
        sub_segment: {
          publictransportation: {
            bm: 'Pengangkutan Awam',
            en: 'Public Transportation',
            order: 1,
          },
          pollution: {
            bm: 'Pencemaran',
            en: 'Pollution',
            order: 2,
          },
          naturaldisaster: {
            bm: 'Bencana Alam',
            en: 'Natural Disaster',
            order: 3,
          },
          forestconservation: {
            bm: 'Pemuliharaan Hutan',
            en: 'Forest Conservation',
            order: 4,
          },
        }
      },
      legal: {
        bm: 'Perundangan',
        en: 'Legal',
        order: 6,
        sub_segment: {
          safetywellbeing: {
            bm: 'Keselamatan & Kesejahteraan',
            en: 'Safety & Well Being',
            order: 1,
          },
          enforcement: {
            bm: 'Penguatkuasaan',
            en: 'Enforcement',
            order: 2,
          },
          integrity_corruption: {
            bm: 'Integriti / Rasuah',
            en: 'Integrity / Corruption',
            order: 3,
          },
        },
      },
      other: {
        bm: 'Lain-lain',
        en: 'Others',
        order: 7,
        sub_segment: {
          other: {
            bm: 'Segmen selain senarai di atas',
            en: 'Other segment not listed above',
            order: 1,
          }
        },
      },
    }
  },
  topic: {
    desc_admin: {
      bm: 'Sila pilih topik',
      en: 'Please select the topic',
    },
  },
  idea: {
    desc: {
      bm: 'Sila kemukakan idea anda berdasarkan kategori dan segmen yang telah dipilih. Pastikan idea anda ditulis dengan ringkas, jelas dan teratur. Sekiranya ingin menukar kategori dan/atau segmen, sila kembali ke skrin sebelumnya.',
      en: 'Please submit your idea based on the category and segment that have been selected. Make sure your idea is written in a concise, clear and organized manner. If you want to change category and/or segment, please return to the previous screen',
    }
  },
  win: {
    bm: 'Untuk peluang memenangi hadiah wang tunai, kongsikan idea anda ini dan minta rakan-rakan serta kenalan anda untuk undi idea anda. Idea dengan jumlah undian tertinggi akan menerima hadiah wang tunai RM500.',
    en: 'For a chance to win a cash prize, share your idea and ask your friends and acquaintances to vote for your idea. The idea with the highest number of votes will receive a cash prize of RM500.',
  },
  step_1: {
    title: {
      bm: 'Langkah 1',
      en: 'Step 1',
    },
    desc: {
      bm: 'Muat turun gambar promosi idea anda di bawah.',
      en: 'Download the promotional image of your idea below.',
    }
  },
  step_2: {
    title: {
      bm: 'Langkah 2',
      en: 'Step 2',
    },
    desc: {
      bm: 'Klik butang Salin untuk menyalin pautan dan teks promosi',
      en: 'Click Copy button to get the promotional text and hyperlink to your idea.',
    }
  },
  step_3: {
    title: {
      bm: 'Langkah 3',
      en: 'Step 3',
    },
    desc: {
      bm: 'Gunakan gambar, pautan dan teks promosi di Langkah 1 dan 2 untuk membuat hantaran atau stories di media sosial anda (Facebook/Twitter/Instragram/Whatsapp/Telegram dan sebagainya).',
      en: 'Use the picture, hyperlink and promotional text from Step 1 and 2 to create a post or stories in your social media (Facebook/Twitter/Instragram/Whatsapp/Telegram etc).',
    }
  },
  step_4: {
    title: {
      bm: 'Langkah 4',
      en: 'Step 4',
    },
    desc: {
      bm: 'Minta rakan-rakan, keluarga, dan kenalan supaya undi idea anda. Idea dengan undian tertinggi akan memenangi hadiah wang tunai RM500.',
      en: 'Ask your friends, families and acquaintances to vote for your idea. The idea with the highest votes will win a cash prize of RM500.',
    }
  },
  step_5: {
    title: {
      bm: 'Langkah 5',
      en: 'Step 5',
    },
    desc: {
      bm: 'Pastikan undian dibuat melalui pautan di Langkah 2. Hanya undian di platform Idea Bina Negara sahaja akan dikira dalam penentuan pemenang.',
      en: 'Make sure to vote via the link in Step 2. Only votes on Idea Bina Negara platform will be counted.',
    }
  },
  square_size: {
    bm: 'Saiz Petak',
    en: 'Square Size',
  },
  potrait_size: {
    bm: 'Saiz Portrait',
    en: 'Portrait Size',
  },
  profile: {
    bm: 'Mohon kemaskini maklumat berikut. Maklumat ini akan membantu kerajaan dalam meneliti, mengkelas dan menyusun keutamaan idea-idea yang diterima.',
    en: 'Please update the following information. This information will help the government in examining, classifying and prioritizing the ideas received.',
  },
  label: {
    my_idea: {
      bm: 'Idea Saya',
      en: 'My Ideas',
    },
    all_idea: {
      bm: 'Idea Lain',
      en: 'Other Ideas',
    },
    action_idea: {
      bm: 'Perlu Tindakan',
      en: 'Need Actions',
    },
    or: {
      bm: 'atau',
      en: 'or'
    },
    type: {
      bm: 'Kategori',
      en: 'Category',
    },
    segment: {
      bm: 'Segmen',
      en: 'Segment',
    },
    sub_segment: {
      bm: 'Sub Segmen',
      en: 'Sub Segment',
    },
    idea: {
      bm: 'Idea',
      en: 'Idea',
    }
  },
  button: {
    proceed: {
      bm: 'Teruskan',
      en: 'Proceed',
    },
    new_idea: {
      bm: 'Hantar Idea',
      en: 'Submit Idea',
    },
    new_topic: {
      bm: 'Tambah Topik Baru',
      en: 'Add New Topic',
    },
    see_other: {
      bm: 'Lihat Idea Lain',
      en: 'See Other Ideas',
    },
    see_more_other: {
      bm: 'Lihat Lagi Idea Lain',
      en: 'See More Other Ideas',
    },
    set_as: {
      bm: 'Set Sebagai',
      en: 'Set As',
    },
    done: {
      bm: 'Selesai',
      en: 'Done',
    },
    close: {
      bm: 'Batal',
      en: 'Cancel',
    },
    exit: {
      bm: 'Tutup',
      en: 'Close',
    },
    prev: {
      bm: 'Sebelumnya',
      en: 'Previous',
    },
    next: {
      bm: 'Seterusnya',
      en: 'Next',
    },
    submit: {
      bm: 'Hantar',
      en: 'Submit',
    },
    edit: {
      bm: 'Pinda',
      en: 'Edit',
    },
    approve: {
      bm: 'Diterima',
      en: 'Approve',
    },
    good_idea: {
      bm: 'Idea Menarik',
      en: 'Nice Idea',
    },
    decline: {
      bm: 'Ditolak',
      en: 'Decline',
    },
    update: {
      bm: 'Kemaskini',
      en: 'Update',
    },
    view_profile: {
      bm: 'Papar Profil',
      en: 'View Profile',
    },
    update_profile: {
      bm: 'Kemaskini Profil',
      en: 'Update Profile',
    },
    upvote: {
      bm: 'Undi',
      en: 'Vote',
    },
    share: {
      bm: 'Kongsi',
      en: 'Share',
    },
    delete: {
      bm: 'Padam',
      en: 'Delete',
    },
    logout: {
      bm: 'Log Keluar',
      en: 'Log Out',
    },
    login: {
      bm: 'Log Masuk',
      en: 'Log In',
    },
    download: {
      bm: 'Muat Turun',
      en: 'Download',
    },
    copy: {
      bm: 'Salin',
      en: 'Copy',
    },
    qr: {
      bm: 'Kod QR',
      en: 'QR Code',
    }
  },
  stat: {
    all: {
      bm: 'Semua',
      en: 'All',
      order: 1,
      key: 'ideas'
    },
    unverified: {
      bm: 'Belum Disemak',
      en: 'Unverified',
      order: 2,
      key: 'unverified'
    },
    verified: {
      bm: 'Sudah Disemak',
      en: 'Verified',
      order: 3,
      key: 'verified'
    },
    good_idea: {
      bm: 'Idea Menarik',
      en: 'Nice Idea',
      order: 4,
      key: 'good_idea'

    },
    approve: {
      bm: 'Diterima',
      en: 'Approve',
      order: 5,
      key: 'approve'

    },
    decline: {
      bm: 'Ditolak',
      en: 'Decline',
      order: 6,
      key: 'decline'
    },
  },
  demographic: {
    gender: {
      bm: 'Jantina',
      en: 'Gender',
      order: 1,
    },
    age: {
      bm: 'Umur',
      en: 'Age',
      order: 2,
    },
    state: {
      bm: 'Negeri',
      en: 'State',
      order: 3,
    },
    employment: {
      bm: 'Status Pekerjaan',
      en: 'Employment Status',
      order: 4,
    },
    salary: {
      bm: 'Gaji',
      en: 'Salary',
      order: 5,
    },
    marital: {
      bm: 'Taraf Perkahwinan',
      en: 'Marital Status',
      order: 6,
    },
  },
  option: {
    gender: {
      list: {
        male: {
          bm: 'Lelaki',
          en: 'Male'
        },
        female: {
          bm: 'Perempuan',
          en: 'Female'
        },
      }
    },
    state: {
      list: {
        johor: {
          bm: 'Johor',
          en: 'Johor'
        },
        kedah: {
          bm: 'Kedah',
          en: 'Kedah'
        },
        kelantan: {
          bm: 'Kelantan',
          en: 'Kelantan'
        },
        malacca: {
          bm: 'Melaka',
          en: 'Malacca'
        },
        negerisembilan: {
          bm: 'Negeri Sembilan',
          en: 'Negeri Sembilan'
        },
        pahang: {
          bm: 'Pahang',
          en: 'Pahang'
        },
        penang: {
          bm: 'Pulau Pinang',
          en: 'Penang'
        },
        perak: {
          bm: 'Perak',
          en: 'Perak'
        },
        perlis: {
          bm: 'Perlis',
          en: 'Perlis'
        },
        sabah: {
          bm: 'Sabah',
          en: 'Sabah'
        },
        sarawak: {
          bm: 'Sarawak',
          en: 'Sarawak'
        },
        selangor: {
          bm: 'Selangor',
          en: 'Selangor'
        },
        terengganu: {
          bm: 'Terengganu',
          en: 'Terengganu'
        },
        kualalumpur: {
          bm: 'Kuala Lumpur',
          en: 'Kuala Lumpur'
        },
        labuan: {
          bm: 'Labuan	',
          en: 'Labuan	'
        },
        putrajaya: {
          bm: 'Putrajaya',
          en: 'Putrajaya'
        }
      }
    },
    employment: {
      list: {
        employed: {
          bm: 'Bekerja',
          en: 'Employed',
        },
        selfemployed: {
          bm: 'Bekerja Sendiri',
          en: 'Self Employed',
        },
        unemployed: {
          bm: 'Menganggur',
          en: 'Unemployed',
        },
      }
    },
    salary: {
      order: true,
      list: {
        salaryLvl1: {
          bm: 'Kurang daripada RM2,500',
          en: 'Less than RM2,500',
          order: 1,
        },
        salaryLvl2: {
          bm: 'RM2,500 - RM3,169',
          en: 'RM2,500 - RM3,169',
          order: 2,
        },
        salaryLvl3: {
          bm: 'RM3,170 - RM3,969',
          en: 'RM3,170 - RM3,969',
          order: 3,
        },
        salaryLvl4: {
          bm: 'RM3,970 - RM4,849',
          en: 'RM3,970 - RM4,849',
          order: 4,
        },
        salaryLvl5: {
          bm: 'RM4,850 - RM5,879',
          en: 'RM4,850 - RM5,879',
          order: 5,
        },
        salaryLvl6: {
          bm: 'RM5,880 - RM7,099',
          en: 'RM5,880 - RM7,099',
          order: 6,
        },
        salaryLvl7: {
          bm: 'RM7,110 - RM8699',
          en: 'RM7,110 - RM8699',
          order: 7,
        },
        salaryLvl8: {
          bm: 'RM8700 - RM10,959',
          en: 'RM8700 - RM10,959',
          order: 8,
        },
        salaryLvl9: {
          bm: 'RM10,960 - RM15,039',
          en: 'RM10,960 - RM15,039',
          order: 9,
        },
        salaryLvl10: {
          bm: 'Lebih daripada RM15,039',
          en: 'More than RM15,039',
          order: 10,
        },
      }
    },
    marital: {
      list: {
        single: {
          bm: 'Bujang',
          en: 'Single',
        },
        married: {
          bm: 'Berkahwin',
          en: 'Married',
        },
        divorce: {
          bm: 'Bercerai',
          en: 'Divorce',
        },
      }
    },
  },
  placeholder: {
    select: {
      bm: 'Sila pilih',
      en: 'Please choose',
    },
    age: {
      bm: 'Masukkan umur',
      en: 'Type your age',
    },
    idea: {
      bm: 'Masukkan idea di sini',
      en: 'Type your idea here',
    }
  },
  quote: [{
      bm: 'Apa idea yang bermain di fikiran anda, [User]?',
      en: 'What’s on your mind, [User]?',
      icon: 'bulb'
    },
    {
      bm: '[User], apa perubahan yang ingin anda lihat berlaku di negara kita?',
      en: '[User], what changes you want to see for our country?',
      icon: 'flash'
    },
    {
      bm: '[User], bantuan apakah yang anda harapkan dari Kerajaan?',
      en: '[User], which kind of help you want from the Government?',
      icon: 'briefcase'
    },
    {
      bm: '[User], apa yang anda fikir Kerajaan patut lakukan untuk menggembirakan rakyat Malaysia?',
      en: '[User], what you think the Government should do to make Malaysians happy?',
      icon: 'chatbox-ellipses'
    },
    {
      bm: '[User], adakah anda mempunyai idea yang anda ingin Kerajaan perhalusi?',
      en: '[User], do you have any idea you want the Government to look into?',
      icon: 'easel'
    },
  ],
  submitted_idea: {
    header: {
      bm: 'Tahniah',
      en: 'Congratulations'
    },
    message: {
      bm: 'Sumbangan idea anda telah diterima. Terima kasih kerana bersama-sama kami membina negara.',
      en: 'Your idea contribution has been accepted. Thank you for building the country together.',
    }
  },
  empty_idea: {
    bm: 'Kami menanti idea segar daripada anak muda Malaysia. Klik Hantar Idea untuk kongsikan aspirasi anda. Kami mendengar.',
    en: 'We look forward to fresh ideas from young Malaysians. Click Submit Idea to share your aspirations. We are listening.'
  },
  not_found_idea: {
    bm: '<h1>Idea Tidak Dijumpai</h1>Pautan anda mungkin salah, atau idea tersebut mungkin telah dipadam/ ditandakan sebagai tidak sesuai.',
    en: '<h1>Idea Not Found</h1>The link you may be broken, or the idea may have been deleted/ flagged as inappropriate.'
  },
  error: {
    bm: '',
    en: '',
  },
  copyright: {
    bm: '&copy; Hakcipta 2022<br>Jabatan Komunikasi Komuniti, Jabatan Perdana Menteri',
    en: '&copy; Copyright 2022<br>Jabatan Komunikasi Komuniti, Jabatan Perdana Menteri',
  },
  confirm_action: {
    header: {
      bm: 'Sahkan!',
      en: 'Confirm!',
    },
    message: {
      bm: 'Sahkan untuk [action]?',
      en: 'Confirm to [action]?',
    },
    button_cancel: {
      bm: 'Batal',
      en: 'Cancel',
    },
    button_ok: {
      bm: 'Teruskan',
      en: 'Proceed',
    },
  },
  share: {
    text: {
      bm: '\nJom sokong idea ini! ❤️ #ideabinanegara',
      en: '\nLet\'s support this idea! ❤️ #ideabinanegara',
    },
    my_text: {
      bm: '\nJom sokong idea saya! ❤️ #ideabinanegara',
      en: '\nLet\'s support my idea! ❤️ #ideabinanegara',
    },
    dialogTitle: {
      bm: 'Kongsi dengan orang lain',
      en: 'Share with others',
    },
  },
  toast: {
    message: {
      bm: 'Pautan idea telah disalin',
      en: 'The idea link has been copied'
    }
  }
};
