/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NavController, LoadingController, AlertController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { setting } from './setting';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Clipboard } from '@capacitor/clipboard';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  language = 'bm';
  setting = setting;
  ideaType;
  segment;
  subSegment;
  myIdea = {};
  allIdea = {};
  syncIdea = [];
  user;
  userData;
  userLoaded;
  stat;
  allIdeaData;
  syncIdea$: Subscription;
  ideas$: Subscription;
  userData$: Subscription;
  private storage$: Storage | null = null;

  constructor(
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private storage: Storage) {
      this.init();
  }

  async init() {
    this.auth.onAuthStateChanged(async () => await this.userInfo());
    const storage = await this.storage.create();
    this.storage$ = storage;
    const language = await storage.get('language');
    if (language) {
      this.language = language;
    }
    this.firestore.collection<any>('stat')
    .snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const id = a.payload.doc.id;
        const data = a.payload.doc.data();
        return { [id]: data.total };
      })))
    .subscribe(res => {
      let stat;
      if(res) {
        res.forEach(item => stat = { ...stat, ...item});
        this.stat = stat;
      }
    });
  }

  changeLanguage(e) {
    this.language = e.detail.value;
    this.storage$?.set('language', this.language);
  }

  isWebview(agent) {
    return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(agent);
  }

  async validateQR(id) {
    const user = await this.auth.currentUser;
    await this.firestore.doc<any>(`token/${user.uid}`).set({ id });
    return true;
  }

  loginWithProvider(provider) {
    if(this.isWebview(navigator.userAgent) && (provider === 'google' || provider === 'twitter' || provider === 'yahoo')) {
      const location = window.location.protocol + '//' + window.location.host + window.location.pathname;
      this.alertCtrl.create({
        header: 'Error',
        message: 'This page cannot be opened using in app browser. To proceed, please copy the following link and open it in your browser (Safari/Chrome). <div class="ion-margin-top ion-text-center"><b>'+ location +'</b></div>',
        buttons: [{
          text: 'Cancel',
          handler: () => {}
        }, {
          text: 'Copy Link',
          handler: async () => {
            await Clipboard.write({
              url:  location
            });
          }
        }]
      }).then(alert => alert.present());
    } else {

    let providerData;

    if (provider === 'google') {
      providerData = new firebase.auth.GoogleAuthProvider();
      providerData.setCustomParameters({
        prompt: 'select_account'
      });
    } else if (provider === 'apple') {
      providerData = new firebase.auth.OAuthProvider('apple.com');
      providerData.addScope('name');
    } else if (provider === 'facebook') {
      providerData = new firebase.auth.FacebookAuthProvider();
      providerData.setCustomParameters({
        display: 'popup'
      });
    } else if (provider === 'twitter') {
      providerData = new firebase.auth.TwitterAuthProvider();
    } else if (provider === 'microsoft') {
      providerData = new firebase.auth.OAuthProvider('microsoft.com');
    } else if (provider === 'yahoo') {
      providerData = new firebase.auth.OAuthProvider('yahoo.com');
      providerData.addScope('profile');
    }

    providerData.addScope('email');

    this.auth.signInWithRedirect(providerData)
      .catch(err => {
        if(err.code === 'auth/account-exists-with-different-credential') {
          this.promptAlert(err);
        } else if(err.code === 'auth/popup-blocked') {
          this.auth.signInWithRedirect(providerData);
        }
      });
    }
  }

  async promptAlert(data) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: 'An account already exists with the same email address but different sign-in credentials. Proceed to link your account with previous credential?',
      buttons: [{
        text: 'Cancel',
        handler: () => {}
      }, {
        text: 'Proceed',
        handler: () => this.linkAccount(data)
      }]
    });

    await alert.present();
  }

  linkAccount(data) {
    this.auth.fetchSignInMethodsForEmail(data.email)
      .then(async provId => {
        const providerData = new firebase.auth.OAuthProvider(provId[0]);
        providerData.setCustomParameters({
          login_hint: data.email
        });
        await this.auth.signInWithRedirect(providerData);
        const user = await this.auth.currentUser;
        user.linkWithCredential(data.credential);
      });
  }

  async userInfo() {
    this.user = await this.auth.currentUser;
    if (this.user) {
      if(!this.userData) {
        this.userData$ = this.firestore.doc<any>(`users/${this.user.uid}`)
          .valueChanges()
          .subscribe(res => {
            this.userData = res;
            this.userLoaded = true;
          });
      }
    } else {
      this.userLoaded = true;
      this.unsubscribe(this.userData$);
    }
  }

  async loadMyIdea() {
    const user = await this.auth.currentUser;
    if (user) {
      this.ideas$ = this.firestore.collection<any>('ideas', refs => refs
        .where('submittedBy', '==', user.uid)
        .where('deleted', '==', null))
      .snapshotChanges()
      .subscribe(res => {
        res.map(a => {
          const id = a.payload.doc.id;
          const data = a.payload.doc.data();
          if(!this.myIdea[id]) {
            this.myIdea[id] = { id, ...data };
          } else {
            this.myIdea[id].idea = data.idea;
            this.myIdea[id].segment = data.segment;
            this.myIdea[id].subSegment = data.subSegment;
            this.myIdea[id].type = data.type;
            this.myIdea[id].status = data.status;
            this.myIdea[id].upvoted = data.upvoted;
          }
        });
      });
    }
  }

  loadAllIdea() {
    return this.firestore.doc<any>(`setting/good_idea`).get().toPromise();
  }

  loadSelectedIdea(selectedId: Array<any>) {
    this.allIdea = {};
    this.firestore.collection<any>('ideas', ref => ref.where('id', 'in', selectedId)).snapshotChanges().subscribe(res => {
      res.map(a => {
        const id = a.payload.doc.id;
        const data = a.payload.doc.data();
        if(!this.allIdea[id]){
          this.allIdea[id] = { id, ...data };
        } else {
          this.allIdea[id].idea = data.idea;
          this.allIdea[id].segment = data.segment;
          this.allIdea[id].subSegment = data.subSegment;
          this.allIdea[id].type = data.type;
          this.allIdea[id].status = data.status;
          this.allIdea[id].author = data.author;
          this.allIdea[id].upvoted = data.upvoted;
        }
      });
    });
  }

  async deleteIdea(id) {
    const loader = await this.loadingCtrl.create();
    loader.present();
    await this.firestore.collection('ideas').doc(id)
      .update({
        deleted: true,
        deletedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
    delete this.myIdea[id];
    loader.dismiss();
  }

  async viewIdea(id) {
    await this.userInfo();
    return this.firestore.collection<any>('ideas').doc(id).snapshotChanges().pipe(map(action => {
      const data = action.payload.data();
      if (action.payload.exists === false || data.deleted === true || data.status === 'reject') {
          return { notFound: true };
      } else {
          return { ...data };
      }
    }));
  }

  async submitIdea(type, segment, subSegment, idea) {
    const loader = await this.loadingCtrl.create();
    loader.present();
    const user = await this.auth.currentUser;
    const ideaDoc = await this.firestore.collection('ideas')
      .add({
        type,
        segment,
        subSegment,
        idea,
        submittedBy: user.uid,
        submittedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
    loader.dismiss();
    return ideaDoc;
  }

  async updateProfile(data) {
    const loader = await this.loadingCtrl.create();
    loader.present();
    const user = await this.auth.currentUser;
    await this.firestore.collection('users').doc(user.uid)
      .set({
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      }, {
        merge: true
      });
    loader.dismiss();
  }

  async upvote(id, upvoted) {
    const user = await this.auth.currentUser;
    const batch = this.firestore.firestore.batch();
    batch.set(this.firestore.collection('ideas').doc(id).collection('upvote').doc(user.uid).ref, {
      upvoted,
      upvotedAt: firebase.firestore.FieldValue.serverTimestamp()
    }, {merge: true});
    batch.set(this.firestore.collection('users').doc(user.uid).ref, {
      upvoted: {
        [id]: upvoted
      }
    }, {merge: true});
    batch.set(this.firestore.collection('ideas').doc(id).collection('_counter_shards_').doc().ref, {upvoted: upvoted ? 1 : -1});
    await batch.commit();
  }

  unsubscribe(sub: Subscription) {
    if(sub){
      sub.unsubscribe();
    }
  }

  async signOut(url = '/') {
    const loader = await this.loadingCtrl.create();
    loader.present();
    this.unsubscribe(this.ideas$);
    this.unsubscribe(this.userData$);
    await this.auth.signOut();
    loader.dismiss();
    this.navCtrl.navigateRoot(url);
  }
}
