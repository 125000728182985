import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';
import { MainService } from './main.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private swUpdate: SwUpdate, private alertCtrl: AlertController, public main: MainService) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(res => {
        if(res) {
          this.alertCtrl.create({
            header: 'Update!',
            message: 'An update version is available.',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel'
              }, {
                text: 'Refresh',
                handler: () => {
                  window.location.reload();
                }
              }
            ]
          }).then(alert => alert.present());
        }
      });
    }
  }
}
